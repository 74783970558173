.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.right {
  text-align: right;
}

.remove-padding{
  padding:0px !important;
  margin:0px !important;
}

.less-padding{
  padding:4px !important;
  margin:4px !important;
  right-padding:10px;
}

.centered {
  display: flex;
  
  align-items: center;
}

.meta-text{
  font-size: .8rem;
  color: var(--ion-color-medium);
  display: flex;
  align-items: center;
  float: left;
  padding-left: 5px
}

.meta-small-text{
  font-size: .5rem;
  color: var(--ion-color-medium);
  display: flex;
  align-items: center;
  padding: 0px;
  padding-left: 5px;
  margin: 0px;
}

.school-text{
  font-size: .8rem;
  color: var(--ion-color-medium);
  
}
@media (orientation:portrait) { 
  .card-small-margin {
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: pre-wrap;
  }
}

@media (orientation:landscape) and (min-device-width: 700px) { 
  .card-small-margin {
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    max-width: 60%;
    min-width: 600px;
    white-space: pre-wrap;
  }
}

@media (orientation:portrait) { 
  .input-card-small-margin {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (orientation:landscape) and (min-device-width: 700px) { 
  .input-card-small-margin {
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;  
    max-width: 60%;
    min-width: 600px;
  }
}

@media (orientation:portrait) { 
  .divider {
    margin-top: 10px;
    margin-bottom: 10px;
    --background: var(--ion-color-light);
  }
}

@media (orientation:landscape) and (min-device-width: 700px) { 
  .divider {
    position: relative;
    left: 0;  
    max-width: 61%;
    min-width: 610px;
    --background: var(--ion-color-light);
    border-radius: 10px;
    border: 2px solid var(--ion-color-light);
    background-color: var(--ion-background-color);
    margin:5px;
  }
}

.comment-area{
  font-size: 1.0rem;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  min-height: 50px;
}

.vote-text{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1rem;
}

.vote-block{
  float: right;
}

.post-button{
  --background:linear-gradient(120deg, #ffd96e 00%, #b46eff 100%);
  color: var(--ion-color-light);
}

.comment-button{
  border-color: #666;
  color: #666;
  padding:0px !important;
  margin:0px !important;
  display: flex;
  float: right;
}

.delete-button{
  float:right;
  display: inline-block;
}

.comment-button-gradient{
  position: relative;
  float: right;
  display: inline-block;
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), linear-gradient(120deg, #ffd96e 00%, #b46eff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  --color: #444;
  height:30px;
}

.center-main{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

@media (orientation:landscape) and (min-device-width: 700px) { 
  .comment-main {
    position: relative;
    left: 0;
    max-width: 60%;
    min-width: 600px;
  }
}

@media (orientation:landscape) and (min-device-width: 700px) { 
  .comment-list {
    position: relative;
    left: 0;
    max-width: 60%;
    min-width: 600px;
  }
}

.input-text{
  height: auto;
  overflow: auto;
  min-height: 80px;
}

.card-text{
  max-height: 15em;
  overflow: hidden;
}

.card-text:before{
  content:'';
  width:100%;
  height:15em;    
  position:absolute;
  left:0;
  top:1.4em;
  background:linear-gradient(transparent 14em, var(--ion-background-color));
}

.card-core{

}