.comment-input{
	padding:1%;
}

@media (orientation:landscape) and (min-device-width: 700px) { 
  .comment-input {
    position: relative;
    left: 0;
    max-width: 60%;
    min-width: 600px;
  }
}

.comment-outline{
  border-radius: 10px;
  border: 2px solid var(--ion-color-medium);
  background-color: var(--ion-background-color);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.comment-label{
  font-size: .8rem;
  color: grey;
  position: left;
  width: 100vw;
  padding-left: 5vw;
}

.comment-box-container{
	background: var(--ion-background-color);
}

.segment-buttons{
	bottom:5%;
	left:10%;
	right:10%;
	width:80%;
}

.comment-text{
	background-color: var(--ion-background-color);
	padding: 0px;
	min-height: 30px;
	height: auto;
	overflow: auto;
}

.comment-submit-button{

  position: absolute;
  right: 5px;
  bottom: 5px;
  --background:linear-gradient(120deg, #ffd96e 00%, #b46eff 100%);
  color: var(--ion-color-light);
}

.toolbar-color{
  background: #ffd96e;
  --background: linear-gradient(90deg, #ffd96e 00%, #b46eff 100%);
}

.hot-color{
  --background-checked: #ffd96eEE;
  	--indicator-color: transparent;
	--color-checked:black;
}

.cool-color{
	--background-checked: #b46effEE;
	--indicator-color: transparent;
	--color-checked:white;
}

.med-color{
	--background-checked: #FFF;
	--indicator-color: transparent;
	--color-checked:black;
}

.remove-padding{
  padding:0px !important;
  margin:0px !important;
}

.profile-points{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: grey;
  height: 7vh;
}

@media (orientation:portrait) { 
  .swidth {

  }
}

@media (orientation:landscape) and (min-device-width: 700px){ 
  .swidth {
    position: relative;
    left: 10px;
    max-width: 60%;
    min-width: 600px;
  }
}

.about-select{
  max-width: 200px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid var(--ion-color-medium);
  background-color: var(--ion-background-color);
}

.about-content{
  margin: 10px;
  margin-top: 20px;
  padding: 0px;
}
